import { Column, CustomCellRendererSelector, GridApi } from '@plarin/inputs';
import { ManageYDTabNameEnum, getSafeParsedLocalStorage, isUrlProd } from '@plarin/utils';
import React from 'react';
import { TMetric, TMetricGroup, TYDMetric, TYDMetricGroup } from '../../../types/metrics';
import { TWsDictionary } from '../../../types/profile/types';
import { ManageYDNameCellsEnum, STORAGE_ITEM } from '../../utils/constants';
import { SwitchStatusEntity } from '../switch-status-entity';
import { customColumns } from './custom-columns';
import { defaultColumns } from './default-columns';

const dynamicCheckbox = (el: Column, isGroupingActive: boolean) => {
  return {
    ...el,
    headerName: '',
    maxWidth: isGroupingActive ? 64 : 40,
    minWidth: isGroupingActive ? 64 : 40,
    cellRendererParams: {
      ...el.cellRendererParams,
      loaderPrecisePositioning: isGroupingActive ? -8 : undefined,
    },
  };
};

const createSwitchButton = (tabName: ManageYDTabNameEnum, data: any, gridApi: GridApi) => {
  return <SwitchStatusEntity tabNameYd={tabName} data={data} gridApi={gridApi} network="yd" />;
};

const getDefaultColumns = (
  tabName: ManageYDTabNameEnum,
  checkedColumns: string[] = [],
  isGroupingActive: boolean,
): Column[] => {
  const columns = [
    defaultColumns.CHECKBOX,
    defaultColumns.ACCOUNTS,
    defaultColumns.CAMPAIGNS,
    defaultColumns.GROUPS,
    defaultColumns.ADS,
  ];

  const checkedColumnAccountName = checkedColumns.includes('account_info');

  switch (tabName) {
    case ManageYDTabNameEnum.ACCOUNTS: {
      return columns
        .filter((_, index) => index === 0 || index === 1)
        .map(el => {
          if (el.field === 'checkbox') {
            return dynamicCheckbox(el, isGroupingActive);
          }
          if (el.field === 'accountInfo') {
            return {
              ...el,
              cellRendererParams: { ...el.cellRendererParams, tabName },
              headerComponentParams: { subheader: 'ID Клиента', isPinned: true },
              cellRendererSelector: CustomCellRendererSelector,
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,
                hasOrgHierarchy: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') && !params.node.data.isGroupRow,
              },
            };
          }

          return el;
        });
    }
    case ManageYDTabNameEnum.CAMPAIGNS: {
      return columns
        .filter((_, index) => index === 0 || index === 1 || index === 2)
        .map(el => {
          if (el.field === 'checkbox') {
            return dynamicCheckbox(el, isGroupingActive);
          }
          if (el.field === 'campaignName') {
            return {
              ...el,
              cellRendererParams: {
                ...el.cellRendererParams,
                tabName,
                createSwitchButton: createSwitchButton,
              },
              cellRendererSelector: CustomCellRendererSelector,
              headerComponentParams: { subheader: 'ID Кампании', isPinned: true },
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,
                hasOrgHierarchy: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') && !params.node.data.isGroupRow,
              },
            };
          }
          return el;
        });
    }
    case ManageYDTabNameEnum.GROUPS: {
      return columns

        .filter((_, index) => index === 0 || index === 2 || index === 3)
        .map(el => {
          if (el.field === 'checkbox') {
            return dynamicCheckbox(el, isGroupingActive);
          }
          if (el.headerName === 'Группа объявлений') {
            return {
              ...el,
              cellRendererParams: {
                ...el.cellRendererParams,
                tabName,
                // isAdsPreview: true,
              },
              cellRendererSelector: CustomCellRendererSelector,
              headerComponentParams: { subheader: 'ID Объявления', isPinned: true },
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,
                hasOrgHierarchy: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') && !params.node.data.isGroupRow,
              },
            };
          }
          return el;
        });
    }
    case ManageYDTabNameEnum.ADS: {
      return columns
        .filter(
          (_, index) =>
            (index === 2 && checkedColumns.includes('campaign_name')) || index === 4 || index === 3 || index === 0,
        )
        .map(el => {
          if (el.field === 'checkbox') {
            return dynamicCheckbox(el, isGroupingActive);
          }
          if (el.field === 'adId') {
            return {
              ...el,
              headerComponentParams: { subheader: 'ID Объявлений', isPinned: true },
              cellRendererParams: {
                ...el.cellRendererParams,
                tabName,
                createSwitchButton: createSwitchButton,
              },
              cellRendererSelector: CustomCellRendererSelector,
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,
                hasOrgHierarchy: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') && !params.node.data.isGroupRow,
              },
            };
          }
          return el;
        });
    }

    default:
      return [];
  }
};

const needColumnsPaths = {
  // mt_project: 'PROJECTS',
  campaign_status: 'STATUS',
  // ad_group_status: 'STATUS',
  ad_status: 'STATUS',
  // ad_plan_start: 'PERIOD',
  // price: 'PRICE',
  // satistics_fast: 'FAST_STAT',
  // satistics_fast72: 'FAST_STAT_72',
  // ad_plan_lifetime: 'BUDGET_LIFETIME',
  // ad_plan_daily: 'BUDGET_DAILY',
  account_balance: 'DEFAULT',
  daily_budget_amount: 'BUDGET',

  statistics_spent: 'DEFAULT',
  statistics_impressions: 'DEFAULT',
  statistics_clicks: 'DEFAULT',
  statistics_conversions: 'DEFAULT',
  statistics_ctr: 'DEFAULT',
  statistics_cpc: 'DEFAULT',
  statistics_cpm: 'DEFAULT',
  statistics_ecpm: 'DEFAULT',
  statistics_crr: 'DEFAULT',
  statistics_cpa: 'DEFAULT',
  statistics_cr: 'DEFAULT',
  statistics_revenue: 'DEFAULT',
};

const getColumns2 = (
  names: TYDMetricGroup['fields'],
  wsDictionary: TWsDictionary,
  tabName: keyof typeof ManageYDNameCellsEnum,
) => {
  const columns: Column[] = [];
  Object.keys(needColumnsPaths).forEach(path => {
    if (
      // для скрытия колонок трансляция когда на бэке не скрыты)
      (tabName === 'groupName' && path === 'campaign_status') ||
      (tabName === 'adId' && path === 'campaign_status')
    ) {
      return null;
    }
    // if (path === 'statistics_ecpm') {
    //  names.forEach(
    //   // @ts-ignore
    //    el =>
    //      el.path === path &&
    //      el.checked &&
    //      columns.push(customColumns(el, wsDictionary, tabName)[needColumnsPaths[path]]),
    //  );
    // } else {
    names.forEach(metric => {
      if (path === metric.path) {
        // @ts-ignore
        columns.push(customColumns(metric, wsDictionary, tabName)[needColumnsPaths[path]]);
      }
    });
    // }
  });
  return columns;
};

const otherColumns = (
  names: TYDMetricGroup['fields'],
  wsDictionary: TWsDictionary,
  tabName: keyof typeof ManageYDNameCellsEnum,
) => {
  return names
    .filter(el => el.checked ?? (!el.hidden && !Object.keys(needColumnsPaths).includes(el.path)))
    .filter(el => (isUrlProd ? !el.hidden : true) && el.checked)
    .filter(
      el =>
        el.path !== 'account_name' &&
        el.path !== 'ad_plan_name' &&
        el.path !== 'campaign_name' &&
        el.path !== 'ad_name',
      //   el.path !== 'campaign_status_clarification' &&
      //   el.path !== 'ad_status_clarification' &&
      //   el.path !== 'campaign_state' &&
      //   el.path !== 'campaign_status' &&
      //   el.path !== 'ad_group_status' &&
      //   el.path !== 'ad_group_state' &&
      //   el.path !== 'ad_status' &&
      //   el.path !== 'ad_state'&&
      // el.path !== 'strategy_id' &&
      // el.path !== 'daily_budget_mode' &&
      // el.path !== 'weekly_spend_limit' &&
      // el.path !== 'budget_type' &&
      // el.path !== 'cpb_spend_limit' &&
      // el.path !== 'cpb_start_date' &&
      // el.path !== 'cpb_end_date' &&
      // el.path !== 'cpb_auto_continue' &&
      // el.path !== 'bidding_strategy_type',
    )
    .map(el => customColumns(el, wsDictionary, tabName).DEFAULT);
};

export const dynamicColumnsYDRenderer = (
  names: TYDMetric[],
  tabName1: ManageYDTabNameEnum,
  tabName2: keyof typeof ManageYDNameCellsEnum,
  wsDictionary: TWsDictionary,
  isGroupingActive: boolean,
) => {
  const checkedColumns = getSafeParsedLocalStorage<string[]>(STORAGE_ITEM.localStorage.METRICSYD);
  return [
    ...getDefaultColumns(tabName1, checkedColumns ? checkedColumns : [], isGroupingActive),
    ...getColumns2(names, wsDictionary, tabName2),
    ...otherColumns(names, wsDictionary, tabName2),
  ];
};
