import { ManageVkTabNameEnum } from '@plarin/utils';

export const navigationDict = {
  [ManageVkTabNameEnum.ACCOUNTS]: {
    nextCellName: 'adplans',
  },
  [ManageVkTabNameEnum.ADPLANS]: {
    nextCellName: 'adgroups',
  },
  [ManageVkTabNameEnum.ADGROUPS]: {
    nextCellName: 'ads',
  },
  [ManageVkTabNameEnum.ADS]: {
    nextCellName: 'ads',
  },
};

export const getFieldsByCellName = (cellName: string, data: any, tabName: string) => {
  const fieldMapping: any = {
    adplans: {
      objectType: 'Рекламная кампания',
      objectName: data.adPlanName,
      objectId: data.adPlanId,
      issues: tabName === 'adplans' ? data.issues : data.adPlanIssues,
      createdDate: tabName === 'adplans' ? data.created : data.createdAdPlan,
      updatedDate: tabName === 'adplans' ? data.updated : data.updatedAdPlan,
      status: tabName === 'adplans' ? data.status : data.adPlanStatus,
      deliveryStatus: tabName === 'adplans' ? data.deliveryStatus : data.adPlanDeliveryStatus,
    },
    adgroups: {
      objectType: 'Группа объявлений',
      objectName: data.campaignName,
      objectId: data.campaignId,
      issues: tabName === 'adgroups' ? data.issues : data.adGroupIssues,
      createdDate: tabName === 'adgroups' ? data.created : data.createdAdGroup,
      updatedDate: tabName === 'adgroups' ? data.updated : data.updatedAdGroup,
      status: tabName === 'adgroups' ? data.status : data.adGroupStatus,
      deliveryStatus: tabName === 'adgroups' ? data.deliveryStatus : data.adGroupDeliveryStatus,
    },
    ads: {
      objectType: 'Объявление',
      objectName: data.adName,
      objectId: data.key,
      issues: data.issues,
      createdDate: data.created,
      updatedDate: data.updated,
      status: data.status,
      deliveryStatus: data.deliveryStatus,
    },
  };
  return fieldMapping[cellName];
};
