import { Column, CustomCellRendererSelector, GridApi } from '@plarin/inputs';
import { ManageVkTabNameEnum, getSafeParsedLocalStorage, isUrlProd } from '@plarin/utils';
import React from 'react';
import { TMetric, TMetricGroup } from '../../../types/metrics';
import { TWsDictionary } from '../../../types/profile/types';
import { ManageVkNameCellsEnum, STORAGE_ITEM } from '../../utils/constants';
import { SwitchStatusEntity } from '../switch-status-entity';
import { customColumns } from './custom-columns';
import { defaultColumns } from './default-columns';

const dynamicCheckbox = (el: Column, isGroupingActive: boolean) => {
  return {
    ...el,
    headerName: '',
    maxWidth: isGroupingActive ? 64 : 40,
    minWidth: isGroupingActive ? 64 : 40,
    cellRendererParams: {
      ...el.cellRendererParams,
      loaderPrecisePositioning: isGroupingActive ? -8 : undefined,
    },
  };
};

const createSwitchButton = (tabName: ManageVkTabNameEnum, data: any, gridApi: GridApi) => {
  return <SwitchStatusEntity tabNameVk={tabName} data={data} gridApi={gridApi} network="vk" />;
};

const getDefaultColumns = (
  tabName: ManageVkTabNameEnum,
  checkedColumns: string[] = [],
  isGroupingActive: boolean,
): Column[] => {
  const columns = [
    defaultColumns.CHECKBOX,
    defaultColumns.ACCOUNTS,
    defaultColumns.ADPLANS,
    defaultColumns.ADGROUPS,
    defaultColumns.ADS,
  ];

  const checkedColumnAccountName = checkedColumns.includes('account_name');

  switch (tabName) {
    case ManageVkTabNameEnum.ACCOUNTS: {
      return columns
        .filter((_, index) => index === 0 || index === 1)
        .map(el => {
          if (el.field === 'checkbox') {
            return dynamicCheckbox(el, isGroupingActive);
          }
          if (el.field === 'accountName') {
            return {
              ...el,
              cellRendererParams: { ...el.cellRendererParams, tabName },
              headerComponentParams: { subheader: 'ID Рекламного кабинета', isPinned: true },
              cellRendererSelector: CustomCellRendererSelector,
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,

                groupRowPadding1: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 1,
                groupRowPadding2: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 2,
                groupRowPadding3: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 3,
              },
            };
          }

          return el;
        });
    }
    case ManageVkTabNameEnum.ADPLANS: {
      return columns
        .filter((_, index) => index === 0 || (checkedColumnAccountName && index === 1) || index === 2)
        .map(el => {
          if (el.field === 'checkbox') {
            return dynamicCheckbox(el, isGroupingActive);
          }
          if (el.field === 'adPlanName') {
            return {
              ...el,
              headerComponentParams: { subheader: 'ID Кампании', isPinned: true },
              cellRendererParams: {
                ...el.cellRendererParams,
                tabName,
                createSwitchButton: createSwitchButton,
              },
              cellRendererSelector: CustomCellRendererSelector,
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,
                groupRowPadding1: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 1,
                groupRowPadding2: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 2,
                groupRowPadding3: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 3,
              },
            };
          }
          return el;
        });
    }
    case ManageVkTabNameEnum.ADGROUPS: {
      return columns
        .filter((_, index) => index === 0 || (checkedColumnAccountName && index === 1) || index === 2 || index === 3)
        .map(el => {
          if (el.field === 'checkbox') {
            return dynamicCheckbox(el, isGroupingActive);
          }
          if (el.field === 'campaignName') {
            return {
              ...el,
              cellRendererParams: {
                ...el.cellRendererParams,
                tabName,
                createSwitchButton: createSwitchButton,
              },
              cellRendererSelector: CustomCellRendererSelector,
              headerComponentParams: { subheader: 'ID Группы объявлений', isPinned: true },
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,
                groupRowPadding1: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 1,
                groupRowPadding2: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 2,
                groupRowPadding3: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 3,
              },
            };
          }
          return el;
        });
    }
    case ManageVkTabNameEnum.ADS: {
      return columns
        .filter(
          (_, index) =>
            index === 0 ||
            (checkedColumnAccountName && index === 1) ||
            (index === 2 && checkedColumns.includes('ad_plan_name')) ||
            index === 3 ||
            index === 4,
        )
        .map(el => {
          if (el.field === 'checkbox') {
            return dynamicCheckbox(el, isGroupingActive);
          }
          if (el.headerName === 'Объявление') {
            return {
              ...el,
              cellRendererParams: {
                ...el.cellRendererParams,
                tabName,
                createSwitchButton: createSwitchButton,
                isAdsPreview: true,
              },
              cellRendererSelector: CustomCellRendererSelector,
              headerComponentParams: { subheader: 'ID Объявления', isPinned: true },
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,
                groupRowPadding1: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 1,
                groupRowPadding2: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 2,
                groupRowPadding3: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') &&
                  !params.node.data.isGroupRow &&
                  params.node.data?.orgHierarchy?.length > 3,
              },
            };
          }
          return el;
        });
    }
    default:
      return [];
  }
};

const needColumnsPaths = {
  mt_project: 'PROJECTS',
  status: 'STATUS',
  ad_plan_start: 'PERIOD',
  price: 'PRICE',
  satistics_fast: 'FAST_STAT',
  satistics_fast72: 'FAST_STAT_72',
  ad_plan_lifetime: 'BUDGET_LIFETIME',
  ad_plan_daily: 'BUDGET_DAILY',
  account_balance: 'DEFAULT',
  statistics_spent: 'DEFAULT',
  statistics_impressions: 'DEFAULT',
  statistics_clicks: 'DEFAULT',
  statistics_ctr: 'DEFAULT',
  statistics_cpc: 'DEFAULT',
  statistics_cpm: 'DEFAULT',
  statistics_ecpm: 'DEFAULT',
  ad_url: 'LINK',
};

const getColumns2 = (
  names: TMetricGroup['fields'],
  wsDictionary: TWsDictionary,
  tabName: keyof typeof ManageVkNameCellsEnum,
) => {
  const columns: Column[] = [];
  Object.keys(needColumnsPaths).forEach(path => {
    if (path === 'statistics_ecpm') {
      names.forEach(
        // @ts-ignore
        el =>
          el.path === path &&
          el.checked &&
          columns.push(customColumns(el, wsDictionary, tabName)[needColumnsPaths[path]]),
      );
    } else {
      names.forEach(metric => {
        if (path === metric.path) {
          // @ts-ignore
          columns.push(customColumns(metric, wsDictionary, tabName)[needColumnsPaths[path]]);
        }
      });
    }
  });
  return columns;
};

const otherColumns = (
  names: TMetricGroup['fields'],
  wsDictionary: TWsDictionary,
  tabName: keyof typeof ManageVkNameCellsEnum,
) => {
  return names
    .filter(el => !Object.keys(needColumnsPaths).includes(el.path))
    .filter(el => (isUrlProd ? !el.hidden : true) && el.checked)
    .filter(
      el =>
        el.path !== 'account_name' &&
        el.path !== 'ad_plan_name' &&
        el.path !== 'campaign_name' &&
        el.path !== 'ad_name',
    )
    .map(el => customColumns(el, wsDictionary, tabName).DEFAULT);
};

export const dynamicColumnsRenderer = (
  names: TMetric[],
  tabName1: ManageVkTabNameEnum,
  tabName2: keyof typeof ManageVkNameCellsEnum,
  wsDictionary: TWsDictionary,
  isGroupingActive: boolean,
) => {
  const checkedMetricsVK = getSafeParsedLocalStorage<string[]>(STORAGE_ITEM.localStorage.METRICSVK);
  return [
    ...getDefaultColumns(tabName1, checkedMetricsVK ? checkedMetricsVK : [], isGroupingActive),
    ...getColumns2(names, wsDictionary, tabName2),
    ...otherColumns(names, wsDictionary, tabName2),
  ];
};
